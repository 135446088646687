<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
Category Content
       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
       
            
       <b-col md="4">
            <b-form-group
              label="Select Category"
              label-for="blog-edit-course"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
              <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCourseForm.category_id"
                label="type"
                @input="getContent(addCourseForm.category_id)"
                :options="categoriesList"
                :reduce="(val) => val.id"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
           
          <b-col md="4">
            <b-form-group
              label="Category Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name="Category Title"
                  rules="required"
                >
              <b-form-input id="blog-edit-title" v-model="addCourseForm.title" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
          <b-col md="4">
            <b-form-group
              label="Category Title (online)"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="Category Title online"
                  rules="required"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="addCourseForm.online_title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Details (Type)"

              label-for="blog-edit-category"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name="Details"
                  
                >
              <b-form-input
                v-model="addCourseForm.details"
                id="video_link"
              />
               <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1">Online Header image</h4>

              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              > <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="addCourseForm.online_header_image !=''?`https://api.lmitac.com/${addCourseForm.online_header_image}` :Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                   
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="rounded ml-1 mt-2 text-center"
                @click="refPreviewE2.src = Tabimage
                addCourseForm.online_header_image=''">
                Remove Image
              </b-button>
            </div>
            <b-media-body>
              <b-card-text class="mt-1"> </b-card-text>
              <div class="d-inline-block mt-1">
                  <b-form-group
                      label="Header Online Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                   <validation-provider
                  #default="{ errors }"
                  name="Tab Image "
                  rules="required"
                >
                    <b-form-file
                      ref="refInputE2"
                      v-model="addCourseForm.online_header_image"
                      accept=".jpg, .png, .gif, .jpeg"
                      placeholder="Choose file"
                      @input="inputImageRendererTab"
                    />
                     <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
                    <b-form-group
                      label="Header Online Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_online_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">Header image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              > <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="addCourseForm.header_image !=''?`https://api.lmitac.com/${addCourseForm.header_image}` :Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                 
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="rounded ml-1 mt-2 text-center"
                @click="refPreviewEl.src = Tabimage
                addCourseForm.header_image=''">
                Remove Image
              </b-button>
            </div>
            <b-media-body>
              <b-card-text class="mt-1"> </b-card-text>
              <div class="d-inline-block mt-1">
                  <b-form-group
                      label="Header Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                   <validation-provider
                  #default="{ errors }"
                  name="Header Image "
                  rules="required"
                >
                    <b-form-file
                      ref="refInputEl"
                      v-model="addCourseForm.header_image"
                      accept=".jpg, .png, .gif, .jpeg"
                      placeholder="Choose file"
                      @input="inputImageRenderer"
                    />
                     <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
                    <b-form-group
                      label="Header image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_header_image"
                        v-model="addCourseForm.alt_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
         
        
          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="blog-content"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                 <QuillEditor
                  :editorRef="'descriptionEditor'"
                   v-model="addCourseForm.description"
                />
        
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
           <b-col cols="12">
            <b-form-group
              label="Description Online"
              label-for="blog-content"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
      
      <QuillEditor
                  :editorRef="'online_descriptionEditor'"
                     v-model="addCourseForm.online_description"
                />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
    
          
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import Editor from '@tinymce/tinymce-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import QuillEditor from '@core/components/editor/Editor'
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from '@/store'
import Vue from "vue";

export default {
  setup() {
 
    // const store = useStore();
    const refInputEl = ref(null);
      const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);
  const Tabimage =  ref("media/svg/files/blank-image.svg");
    const categoriesList=ref([])
  
 store.dispatch('categories/AllCategory')
        .then(response => {
        
          categoriesList.value=response.data.data
        })
        const getContent = (id) => {
          if(id){

      store.dispatch("categories/GetCategory", { id }).then((response) => {
      // addCourseForm.related_courses=JSON.parse(response?.data.related_courses)
      addCourseForm.title=response?.data.title
      addCourseForm.type=response?.data.type
      addCourseForm.online_title=response?.data.online_title
      addCourseForm.details=response?.data.details
      addCourseForm.header_image=response?.data.header_image
      addCourseForm.online_header_image=response?.data.online_header_image
      addCourseForm.description=response?.data.description
      addCourseForm.online_description=response?.data.online_description
      addCourseForm.alt_online_header_image=response?.data.alt_online_header_image
      addCourseForm.alt_header_image=response?.data.alt_header_image

      })
    }
    else{
      addCourseForm.title=''
      addCourseForm.online_title=''
      addCourseForm.details=''
      addCourseForm.header_image=''
      addCourseForm.description=''
      addCourseForm.online_description=''
      addCourseForm.alt_online_header_image=''
      addCourseForm.alt_header_image=''
      addCourseForm.online_header=''
      addCourseForm.type=''
      refPreviewEl.value.src = "media/svg/files/blank-image.svg";
            refPreviewE2.value.src = "media/svg/files/blank-image.svg";
    }
    };
    const addCourseForm = reactive({
      category_id: "",
      title: "",
      online_title: "",
      online_header_image: [],
      header_image: [],
      alt_online_header_image: "",
      alt_header_image: "",
      description: "",
      online_description: "",
      details:''
     
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
     const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
     const addCourseFormvalidate=ref()

    const save = () => {
      
    
      const formData = new FormData();

    
        
         addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append("category_id", addCourseForm.category_id);
formData.append("title", addCourseForm.title);
formData.append("online_title", addCourseForm.online_title);

formData.append("details", addCourseForm.details);
formData.append("online_header_image", addCourseForm.online_header_image);
formData.append(" header_image", addCourseForm.header_image);
formData.append("alt_online_header_image", addCourseForm.alt_online_header_image);
formData.append("alt_header_image", addCourseForm.alt_header_image);
formData.append("description", addCourseForm.description);
formData.append(" online_description", addCourseForm.online_description);

   
           store.dispatch('content/addCategoryContent',formData)

        .then(response => {
          refPreviewEl.value.src = "media/svg/files/blank-image.svg";
            refPreviewE2.value.src = "media/svg/files/blank-image.svg";
       
           Vue.swal({
                       title: "Category Content Added ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
  
        }})
    };
    function example_image_upload_handler (blobInfo, success, failure, progress) {
  var xhr, formData;
let token=localStorage.getItem('token')
console.log('token',token)
  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open('POST', 'https://api.lmitac.com/api/upload_image_tiny_mce');

  xhr.upload.onprogress = function (e) {
    progress(e.loaded / e.total * 100);
  };
  xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  xhr.onload = function() {
    var json;

    if (xhr.status === 403) {
      failure('HTTP Error: ' + xhr.status, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure('HTTP Error: ' + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location != 'string') {
      failure('Invalid JSON: ' + xhr.responseText);
      return;
    }

    success(json.location);
  };

  xhr.onerror = function () {
    failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
  };

  formData = new FormData();
  formData.append('image', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
};
    return {
      example_image_upload_handler,
      refInputEl,
      refPreviewEl,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      categoriesList,
      getContent,
     

      save,
       required, email
    };
  },

  components: {
    Editor,
    BCard,
    BMedia,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    Editor,
    BLink,
  
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    QuillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mb-2.col-12 {
    margin: -1px !important;
  }
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }
  
  </style>